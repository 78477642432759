import { AppContext } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';

import { GiftReturnSettings, GiftReturns, IGrayFeatureEnabledMap, Shops } from 'returns-logics';
import { GetStoreConfigPayload, getShopConfig } from 'returns-logics';

import { getReturnPageUrlByUserName } from '@/config/endPoints';
import getStores from '@/features/returns/resources/getStores';
import {
  getInitialLangByQuery,
  getInitialLng,
  getPersistQuery,
  getReturnsPageAccess,
  getShopifyAppKey,
  insertSecurityHeaders,
  isAppProxy,
  isCfVistorHttp,
  queryToString,
} from '@/features/returns/utils/common';
import {
  ReturnsPageAccessCode,
  ReturnsPageAccessStatus,
  SHOPPER_HOST_KEY,
} from '@/features/returns/utils/constants';
import { SSRException, UnexpectedHostnameException } from '@/utils/errorHandler';
import { logger } from '@/utils/logger';

const {
  publicRuntimeConfig: { APP_ENV },
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();

export type CustomShopifyAppProxyConfig = {
  [orgId: string]: {
    headerSelector: string;
  };
};
export const shopifyAppProxyConfig: CustomShopifyAppProxyConfig = {
  '703bf387c72f4fa2b1f7e81f4199905a': {
    headerSelector: '#MainHeader',
  },
  '1c29b3ea3b0b4f0fbc2f3e8e42a4fcd1': {
    headerSelector: "data-section-id='header'",
  },
  '3e10457d249d4df09c1d906b0a83f625': {
    headerSelector: "data-section-id='header'",
  },
};

const getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { query } = appContext.ctx;
  const { isPreview } = appContext.router;

  const req = appContext.ctx.req as NonNullable<typeof appContext.ctx.req>;
  const res = appContext.ctx.res as NonNullable<typeof appContext.ctx.res>;

  const headersList = req.headers;
  const { path_prefix: pathPrefix, shop, mode: compatMode, hostname, lang } = query;

  const pathname = req.url || '';

  let shopInfo: Shops | null = {} as Shops;
  let giftReturnSetting: GiftReturns | null = null;
  let appProxy: Returns.AppProxyInfo | null = null;
  let initialLang = 'en-US';
  let grayFeatureEnabledMap: IGrayFeatureEnabledMap | null = null;
  let returnsPageAccess: Returns.ReturnsPageAccessResult | null = null;

  const uaString = headersList['user-agent'] as string;
  const cfVisitorHeader = headersList['cf-visitor'] as string;
  // 判断特定协议头来确定是否要转发https
  const httpsRedirectUrl = isCfVistorHttp(cfVisitorHeader)
    ? `https://${req.headers.host}${pathname}`
    : '';
  // [compat] is for compatibility with old typo issue. For some users who have already used the
  // old typo with iframe, we need to keep the old typo to avoid breaking their existing logic.
  const acceptLanguageHeader = headersList['accept-language'] as string;

  let shopHostName = queryToString(
    hostname || (req as any).cookies[SHOPPER_HOST_KEY] || req.headers.host,
  );
  let isCompact = compatMode === 'compact' || compatMode === 'compat' || false;

  const setAppProxy = isAppProxy(appContext.ctx.res);

  try {
    let initialInput: GetStoreConfigPayload = {} as GetStoreConfigPayload;

    if (!setAppProxy) {
      initialInput = { shopHostName, APP_ENV, AM_API_KEY };
    } else {
      const appKey = getShopifyAppKey(shop as string);
      const res = await getStores(appKey);
      initialInput = { orgId: res.connections[0]?.organization.id, APP_ENV, AM_API_KEY };
    }

    const storeConfig = await getShopConfig({ input: initialInput });

    shopInfo = storeConfig?.shopInfo;

    if (setAppProxy) {
      appProxy = {
        shop: shop as string,
        pathPrefix: pathPrefix as string,
        customizedConfig: shopifyAppProxyConfig[shopInfo.organization.id],
      };
      // Remove header and footer when returns page embedded in Shopify via app proxy
      isCompact = true;
      // 保证 proxy 场景 shopHostName 也是正确的，以便运行 store 现有依赖 shopHostName 的逻辑
      shopHostName = getReturnPageUrlByUserName(shopInfo.organization.short_name);
      // App Proxy: https://shopify.dev/apps/online-store/app-proxies
    }

    returnsPageAccess = getReturnsPageAccess({
      returnsPageAccess: getPersistQuery(appContext.ctx, 'returns_page_access') || '',
      pathname,
      shopInfo,
      isAppProxy: setAppProxy,
    });

    giftReturnSetting = storeConfig?.giftReturnSetting as GiftReturnSettings;

    grayFeatureEnabledMap = storeConfig?.grayFeatureEnabledMap as IGrayFeatureEnabledMap;

    if (getPersistQuery(appContext.ctx, 'lang')) {
      initialLang = getInitialLangByQuery(shopInfo, queryToString(lang));
    } else {
      // in preview mode, ignore the locale
      initialLang = isPreview
        ? shopInfo.default_language
        : getInitialLng(acceptLanguageHeader, shopInfo);
    }

    insertSecurityHeaders(res, shopInfo, APP_ENV === 'development');

    return {
      appProps,
      initialProps: {
        shopInfo,
        giftReturnSetting,
        shopHostName,
        isCompact,
        httpsRedirectUrl,
        appProxy,
        uaString,
        initialLang,
        grayFeatureEnabledMap,
        returnsPageAccess,
        isAppProxy: setAppProxy,
        APP_ENV,
      },
    };
  } catch (error: any) {
    if (
      error instanceof UnexpectedHostnameException ||
      error.code === 404 ||
      // shops 接口迁移到 v4, code 会由 404 变为 404xx,所以这里除以 100
      Math.floor((error.code ?? 0) / 100) === 404
    ) {
      res.statusCode = 404;
      returnsPageAccess = {
        code: ReturnsPageAccessCode.UnexpectedHostname,
        status: ReturnsPageAccessStatus.Denied,
      };
    } else {
      res.statusCode = 500;
      logger.error({
        error: new SSRException(error.message, { cause: error }),
        headers: {
          path: pathname,
          ...req.headers,
        },
      });
      returnsPageAccess = {
        code: ReturnsPageAccessCode.ServerError,
        status: ReturnsPageAccessStatus.Denied,
      };
    }

    insertSecurityHeaders(res, shopInfo, APP_ENV === 'development');

    // 出错后，需要初始化 i8n
    initialLang = getInitialLng(acceptLanguageHeader, shopInfo);

    return {
      appProps,
      initialProps: {
        shopInfo,
        giftReturnSetting,
        shopHostName,
        isCompact,
        httpsRedirectUrl,
        appProxy,
        uaString,
        initialLang,
        grayFeatureEnabledMap,
        returnsPageAccess,
        isAppProxy: setAppProxy,
        APP_ENV,
      },
    };
  }
};

export default getInitialProps;
