import { ErrorResponse } from './ErrorResponse';
import { ResponseBody } from './response';
import { simpleMerge } from './simpleMerge';

import { hostName, internalHostname, v3hostName, v4hostName } from '../constant/hostname';
import { ErrorCode } from '../types';

type PayloadInit<Payload> = Omit<RequestInit, 'body'> & { payload: Payload };
type FetchPayload = BodyInit | null | undefined;
export type RequestOptions<Payload = undefined> = Payload extends FetchPayload
  ? RequestInit
  : PayloadInit<Payload>;

const getIsSeesionExpired = (session: string) => {
  const currTimestamp = Math.round(new Date().getTime() / 1000);

  try {
    const expireTimestamp = JSON.parse(atob(session.split('.')[1])).exp;

    return expireTimestamp <= currTimestamp;
  } catch (e) {
    return false;
  }
};

const getSession = (): string => {
  let session: string;

  try {
    const sessionToken = '';

    session = sessionToken ? `Bearer ${sessionToken}` : '';

    if (session && getIsSeesionExpired(session)) {
      session = '';
    }
  } catch (e) {
    session = '';
  }

  return session;
};

export const getRequestDefaultInit = (): RequestInit => {
  const session = getSession();

  return {
    headers: {
      'Content-Type': 'application/json',
      'returns-authorization': session,
    },
  };
};

export function getRequestInit<Payload = undefined>(
  options?: RequestOptions<Payload>,
): RequestInit {
  const defaultOptions = getRequestDefaultInit();

  const customOptions = options || {};

  const bodyInCustomOptions = (customOptions as RequestInit).body;

  const payloadInCustomOptions = (customOptions as PayloadInit<Payload>).payload;

  if (!bodyInCustomOptions && payloadInCustomOptions) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { payload, ...extra } = customOptions as PayloadInit<Payload>;

    return simpleMerge(
      [
        defaultOptions,
        extra,
        {
          body: JSON.stringify(payloadInCustomOptions),
        },
      ],
      true,
    );
  }

  if (bodyInCustomOptions) {
    delete (defaultOptions.headers as Record<string, any>)['Content-Type'];
    return simpleMerge([defaultOptions, customOptions], true);
  }
  return simpleMerge([defaultOptions, customOptions], true);
}

export function request<Body extends ResponseBody, Payload = any>(
  uri: string,
  options?: RequestOptions<Payload>,
) {
  const init = getRequestInit<Payload>(options);
  const encodeUri = encodeRequestUri(uri);
  if (
    !(init.headers as Record<string, string>)?.['returns-authorization'] &&
    !(
      encodeUri.includes(`${internalHostname()}/shops`) ||
      encodeUri.includes(`${hostName()}/shops`) ||
      encodeUri.includes(`${v4hostName()}/sessions`) ||
      encodeUri.includes(`${v4hostName()}/setting/clickwrap`) ||
      encodeUri.includes(`${v3hostName()}/gift-returns/setting`) ||
      encodeUri.includes(`${v4hostName()}/gift-return-intentions`) ||
      encodeUri.includes(`${v3hostName()}/gift-return-intentions`) ||
      encodeUri.includes(`${v3hostName()}/gift-return-order-matchings`) ||
      encodeUri.includes(`${v4hostName()}/setting/returns`) ||
      encodeUri.includes(`${v4hostName()}/countries`) ||
      encodeUri.includes(`${v4hostName()}/setting/shop`)
    )
  ) {
    return Promise.reject(new ErrorResponse(ErrorCode.JwtValidationFailed));
  }

  return fetch(encodeUri, init)
    .then(async (response) => {
      // 204 would not response body to be parsed to json
      if (response.status === 204) {
        return {} as Body;
      }

      if (response.status >= 200 && response.status < 400) {
        return response.json() as Promise<Body>;
      }

      let res;
      try {
        res = await response.json();
      } catch (e) {
        return Promise.reject(e);
      }
      return Promise.reject(new ErrorResponse(res.meta.code, res.meta.message, res.meta.errors));
    })
    .catch((e) => Promise.reject(e));
}

const encodeRequestUri = (uri: string) => {
  const queryIndex = uri.indexOf('?');

  if (queryIndex !== -1) {
    const path = uri.slice(0, queryIndex);
    const search = uri.substring(queryIndex);
    let encodeSearch = '?';

    const queryList = search.substring(1).split('&');

    // why not use new URLSearchParams(search), eg: '+' will be replace with ' ' when URLSearchParams.get(key)
    for (let i = 0; i < queryList.length; i++) {
      const [key, value] = queryList[i].split('=');
      encodeSearch += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

      if (i !== queryList.length - 1) {
        encodeSearch += '&';
      }
    }
    return path + encodeSearch;
  }

  return uri;
};
