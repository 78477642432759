import {
  type ActorRefFrom,
  ErrorActorEvent,
  type SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  sendParent,
  setup,
  spawnChild,
  stopChild,
} from 'xstate';

import {
  ReplaceTheSameItemChildren,
  ReplaceTheSameItemContext,
  ReplaceTheSameItemEvent,
  ReplaceTheSameItemInput,
} from './types.ts';

import { getAllProducts } from '../../promise/products.ts';
import { ErrorResponse } from '../../request/ErrorResponse.ts';
import { ExchangeMode, PrivateReplaceSameItemDoneEvent } from '../../types';
import { ProductForReplace } from '../../types/products';
import { transformProductItem } from '../../utils/convertor.ts';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils.ts';
import { selectSingleReplaceItemSubFlow } from '../SelectSingleReplaceItemSubFlow/selectSingleReplaceItemSubFlow.tsx';
import { SelectSingleReplaceItemInput } from '../SelectSingleReplaceItemSubFlow/types.ts';

export const replaceTheSameItemSubFlow = setup({
  types: {
    input: {} as ReplaceTheSameItemInput,
    context: {} as ReplaceTheSameItemContext,
    events: {} as ReplaceTheSameItemEvent,
    children: {} as ReplaceTheSameItemChildren,
  },
  actors: { getAllProducts, selectSingleReplaceItemSubFlow },
  actions: {
    updatePreviewData: () => {},
  },
}).createMachine({
  context: ({ input }) => {
    return {
      shopId: input.shopId,
      token: input.token,
      replaceMode: input.replaceMode,
      selectedItems: input.selectedItems,
      productsInfoMap: {},
      hideUnselectableVariant: input.hideUnselectableVariant,
      minInventoryCalculated: input.minInventoryCalculated,
      replaceComment: '',
      supportSelectVariantPlatformOrder: input.supportSelectVariantPlatformOrder,
    };
  },
  entry: 'updatePreviewData',
  initial: 'validateData',
  states: {
    validateData: {
      always: [
        {
          guard: ({ context }) => Boolean(context.selectedItems.length),
          target: 'decideFetchProducts',
        },
        {
          actions: sendParent(() => ({
            type: 'GO_TO_ORDER_LOOKUP',
          })),
        },
      ],
    },
    decideFetchProducts: {
      always: [
        {
          guard: ({ context }) =>
            context.replaceMode === ExchangeMode.SamePrice ||
            context.replaceMode === ExchangeMode.DifferentPrice,

          target: 'fetchProductsInfo',
        },
        {
          target: 'waitingForSelectReplaceItem',
        },
      ],
    },
    fetchProductsInfo: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getAllProducts',
            input: ({ context }) => {
              return {
                token: context.token,
                returnItems: context.selectedItems?.map((item) => {
                  return {
                    itemId: item.itemId,
                    quantity: item.quantity,
                    productId: item.productId,
                    variantId: item.variantId,
                  };
                }),
                needComparePrice: true,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                productsInfoMap: ({ event, context }) => {
                  const productItemsMap = event.output;
                  return Object.keys(productItemsMap).reduce<Record<string, ProductForReplace>>(
                    (previousValue, key) => {
                      previousValue[key] = transformProductItem(
                        productItemsMap[key],
                        context.hideUnselectableVariant,
                        context.minInventoryCalculated,
                      );
                      return previousValue;
                    },
                    {} as Record<string, ProductForReplace>,
                  );
                },
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  console.log('fetchProductsInfo error');
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: '#waitingForSelectReplaceItem',
              },
            ],
          },
        },
        success: {
          type: 'final',
        },
      },
      onDone: {
        target: 'waitingForSelectReplaceItem',
      },
    },
    waitingForSelectReplaceItem: {
      id: 'waitingForSelectReplaceItem',
    },
    selectSingleReplaceItem: {},
    done: {
      type: 'final',
      entry: sendParent(({ context }): PrivateReplaceSameItemDoneEvent => {
        return {
          type: 'replace_same_item_done',
          data: {
            selectedItems: context.selectedItems,
            replaceComment: context.replaceComment,
          },
        };
      }),
    },
  },
  exit: enqueueActions(({ context, enqueue }) => {
    if (context.activeSelectedItem) {
      enqueue.stopChild(context.activeSelectedItem.itemId);
    }
  }),
  on: {
    SELECT_ITEM: {
      target: '.selectSingleReplaceItem',
      actions: [
        assign({
          activeSelectedItem: ({ event }) => event.data.selectedItem,
        }),
        spawnChild('selectSingleReplaceItemSubFlow', {
          syncSnapshot: true,
          id: ({ event }) => {
            assertEvent(event, 'SELECT_ITEM');
            return event.data.selectedItem.itemId;
          },
          input: ({ context, event }): SelectSingleReplaceItemInput => {
            assertEvent(event, 'SELECT_ITEM');
            const itemId = event.data.selectedItem.itemId;
            const productInfo = context.productsInfoMap[itemId];
            return {
              activeSelectedItem: event.data.selectedItem,
              productInfo: productInfo,
              hideUnselectableVariant: context.hideUnselectableVariant,
            };
          },
        }),
      ],
    },
    SELECT_ITEM_CANCEL: {
      target: '.waitingForSelectReplaceItem',
      actions: [
        assign({
          activeSelectedItem: undefined,
        }),
        stopChild(({ event }) => {
          assertEvent(event, 'SELECT_ITEM_CANCEL');
          return event.data.selectedItem.itemId;
        }),
      ],
    },
    SELECT_ITEM_DONE: {
      target: '.waitingForSelectReplaceItem',
      actions: [
        assign({
          activeSelectedItem: undefined,
          selectedItems: ({ context, event }) => {
            assertEvent(event, 'SELECT_ITEM_DONE');
            return context.selectedItems.map((selectedItem) =>
              selectedItem.itemId !== event.data.selectedItem.itemId
                ? selectedItem
                : {
                    ...event.data.selectedItem,
                  },
            );
          },
        }),
        stopChild(({ event }) => {
          assertEvent(event, 'SELECT_ITEM_DONE');
          return event.data.selectedItem.itemId;
        }),
      ],
    },
    GO_TO_NEXT_STATE: {
      target: '.done',
      actions: [
        assign({
          replaceComment: ({ event }) => event.data.replaceComment,
        }),
      ],
    },
  },
});

export type ReplaceTheSameItemSubFlowActorRef = ActorRefFrom<typeof replaceTheSameItemSubFlow>;

export type ReplaceTheSameItemSubFlowSnapshot = SnapshotFrom<typeof replaceTheSameItemSubFlow>;
