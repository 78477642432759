import Image from 'next/image';
import { ReactNode, useMemo } from 'react';

import { Box, Flex, Spinner, useIsMounted } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import {
  backgroundImageClassName,
  containerClassName,
  returnsPageContainer,
  returnsPageContainerInAppProxy,
  scrollbar24,
  scrollbar48,
} from './ReturnsPage.css';
import ReturnsPageFooter from './ReturnsPageFooter';
import ReturnsPageHeader from './ReturnsPageHeader';

import { useLayoutHidden } from '../hooks/useLayoutHidden';
import { useReturns } from '../hooks/useReturns';
import { useShopInfo } from '../hooks/useShopInfo';
import { useSyncXStateAndRoute } from '../hooks/useSyncXStateAndRoute';

interface ReturnsPageProps {
  children: ReactNode;
}

const BackgroundImage = ({ url }: { url?: string }) => {
  const heroImage = useMemo(() => {
    return url ?? require('@/assets/returns-page-background.png').default?.src;
  }, [url]);
  return (
    <Image
      fill
      sizes='100vw'
      src={heroImage}
      className={backgroundImageClassName}
      alt='returns page background'
    />
  );
};

const ReturnsPage = ({ children }: ReturnsPageProps) => {
  const { hiddenHeader, hiddenFooter, fullScreen } = useLayoutHidden();
  const { ultra, paddingTop, paddingBottom } = useDevice();
  /**
   * @argument isCompact
   * @description 是否为紧凑模式布局，该模式下不展示 header 和 footer
   */
  const { isAppProxy, isCompact } = useReturns() ?? {};
  const isMounted = useIsMounted();
  const shopInfo = useShopInfo();
  const heroImage = shopInfo.hero_image?.src;

  useSyncXStateAndRoute();

  return (
    <Flex
      position='relative'
      direction='column'
      className={isAppProxy ? returnsPageContainerInAppProxy : returnsPageContainer}
    >
      <BackgroundImage url={heroImage} />
      {!hiddenHeader && !isCompact && <ReturnsPageHeader shopInfo={shopInfo} />}
      {isMounted ? (
        <Box
          flex={1}
          height={0}
          className={
            fullScreen ? undefined : `${containerClassName} ${ultra ? scrollbar48 : scrollbar24}`
          }
          paddingTop={fullScreen ? undefined : paddingTop}
          paddingBottom={fullScreen ? undefined : paddingBottom}
        >
          <Flex
            direction='column'
            height={fullScreen ? '100%' : undefined}
            // https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
            margin={fullScreen ? undefined : 'auto'}
          >
            {children}
          </Flex>
        </Box>
      ) : (
        <Flex flex={1} height={0} alignItems='center' justifyContent='center'>
          <Spinner color={ColorVars['B&W']['Background']} />
        </Flex>
      )}
      {!hiddenFooter && !isCompact && <ReturnsPageFooter shopInfo={shopInfo} />}
    </Flex>
  );
};

export default ReturnsPage;
