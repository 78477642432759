type Many<T> = T | ReadonlyArray<T>;
type List<T> = ArrayLike<T>;
type PropertyName = string | number | symbol;

type PropertyPath = Many<PropertyName>;

type PartialObject<T> = Partial<T>;

function getPaths(paths: Many<PropertyPath>): PropertyName[] {
  if (!Array.isArray(paths)) {
    return [paths] as PropertyName[];
  } else {
    return paths.reduce<PropertyName[]>((acc, path) => {
      if (Array.isArray(path)) {
        acc.push(...path);
      } else {
        acc.push(path);
      }
      return acc;
    }, []);
  }
}

export function pick<T extends object>(
  object: T | null | undefined,
  ...props: Array<Many<PropertyPath>>
): PartialObject<T> {
  if (!object) {
    return {};
  }

  const result: PartialObject<T> = {};
  // 展平所有传入的属性路径
  const paths = props.flatMap((path) => getPaths(path));

  paths.forEach((path) => {
    if (typeof path === 'string' || typeof path === 'number' || typeof path === 'symbol') {
      if (path in object) {
        // @ts-ignore
        result[path] = object[path];
      }
    }
  });

  return result;
}
// 功能：取数组交集
export function intersection<T>(...arrays: Array<List<T> | null | undefined>): T[] {
  if (arrays.length === 0) return [];
  // 过滤掉 null 或 undefined 的数组
  let filteredArrays = arrays.filter((array) => array != null) as ArrayLike<T>[];

  if (filteredArrays.length === 0) return [];
  // 使用 Array.from 将 ArrayLike 转换为 Array
  let result = Array.from(filteredArrays[0]);

  for (let i = 1; i < filteredArrays.length; i++) {
    let currentArray = Array.from(filteredArrays[i]);
    result = result.filter((item) => currentArray.includes(item));
  }
  return result;
}

export function omit<T extends object, K extends keyof T>(
  object: T | null | undefined,
  ...paths: Array<Many<K>>
): Omit<T, K> {
  let result = {} as T;
  for (let key in object) {
    if (!paths.includes(key as any)) {
      result[key] = object[key];
    }
  }
  return result;
}

/**
 * Sets the value at path of object without mutating the original object. If a portion of the path doesn't exist, it's created.
 * Arrays are created for missing index properties while objects are created for all other missing properties.
 *
 * @param obj The object to modify.
 * @param path The path of the property to set.
 * @param value The value to set.
 * @returns A new object with the updated path.
 */
export function set<T extends object>(obj: T, path: string, value: any): T {
  const keys = path.split('.');
  if (keys.length === 0) {
    return obj;
  }

  const key = keys[0];
  const nextPath = keys.slice(1).join('.');

  if (keys.length === 1) {
    // Base case: if it's the last key, set the value
    return {
      ...obj,
      [key]: value,
    };
  }

  // Recursive case: navigate deeper into the object
  // @ts-ignore
  const nextObject = obj?.[key] ?? (isNaN(Number(keys[1])) ? {} : []);
  return {
    ...obj,
    [key]: set(nextObject, nextPath, value),
  };
}
