/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  sendParent,
  setup,
} from 'xstate';

import {
  ReturnMethodContext,
  ReturnMethodEvent,
  ReturnMethodInput,
  ReturnMethodOutput,
} from './types';

import {
  ReturnMethodSlug,
  SupportDropoffSlug,
  maxHappyReturnSelectedItems,
} from '../../constant/returnMethod';
import {
  batchGetDropoffLocations,
  getDropoffLocations,
  getMatchingMethod,
} from '../../promise/returnMethod';
import { ErrorResponse } from '../../request/ErrorResponse';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { getDropoffSlugs, parseMatchingMethodPayload } from '../../utils/returnMethod';
import { resolveIntentionData } from '../resolveIntentionData';

export const returnMethodSubFlow = setup({
  types: {
    context: {} as ReturnMethodContext,
    input: {} as ReturnMethodInput,
    output: {} as ReturnMethodOutput,
    events: {} as ReturnMethodEvent,
  },
  actors: {
    getMatchingMethod,
    getDropoffLocations,
    batchGetDropoffLocations,
    resolveIntentionData,
  },
  actions: {
    updateSelectedMethodInPreviewMode: () => {},
  },
}).createMachine({
  entry: 'updateSelectedMethodInPreviewMode',
  initial: 'validateData',
  context: ({ input }) => ({
    ...input,
    returnMethods: [],
    dropoffLocations: {},
  }),
  output: ({ context }) => {
    const method = context.returnMethods.find((method) => method.id === context.selectedMethodId);
    return {
      selectedMethod: {
        id: method?.id || '',
        name: method?.name || '',
        description: method?.description || '',
      },
    };
  },
  states: {
    validateData: {
      tags: 'loading',
      always: [
        {
          guard: ({ context }) =>
            context.resolution === undefined || context.selectedItems.length <= 0,
          actions: sendParent({ type: 'GO_TO_ORDER_LOOKUP' }),
        },
        { target: 'beforeGetMatchingMethod' },
      ],
    },
    beforeGetMatchingMethod: {
      description: '判断是不是 EFA 模式',
      initial: 'checkEFA',
      tags: 'loading',
      states: {
        checkEFA: {
          always: [
            {
              description:
                '如果是 EFA 模式，解析 intentionId 拿到 exchangeItems 再获取对应的 matching method',
              guard: ({ context }) => Boolean(context.intentionId),
              target: '#resolveIntentionData',
            },
            {
              description: '不是 EFA 模式，拿外部传入的 exchangeItems 获取 matching method',
              target: '#getMatchingMethod',
            },
          ],
        },
      },
    },
    resolveIntentionData: {
      description: '解析 intentionId',
      id: 'resolveIntentionData',
      tags: 'loading',
      invoke: {
        src: 'resolveIntentionData',
        input: ({ context }) => ({
          token: context.token,
          intentionId: context.intentionId || '',
        }),
        onDone: {
          target: 'getMatchingMethod',
          actions: assign({
            exchangeItems: ({ event }) =>
              (event.output.exchangeItems || []).map((item) => ({
                external_product_id: item.productId,
                external_variant_id: item.variantId,
                product_id: item.productId,
                quantity: item.quantity,
              })),
          }),
        },
        onError: [
          {
            guard: ({ event }) => isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
            actions: enqueueActions(({ enqueue }) => {
              enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                type: 'HANDLE_JWT_ERROR',
              });
            }),
          },
        ],
      },
    },
    getMatchingMethod: {
      description: '获取 return method 列表',
      id: 'getMatchingMethod',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getMatchingMethod',
            input: ({ context }) => ({
              token: context.token,
              ...parseMatchingMethodPayload({
                resolution: context.resolution,
                exchangeItems: context.exchangeItems,
                selectedItems: context.selectedItems,
              }),
            }),
            onDone: {
              target: 'success',
              actions: assign({ returnMethods: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: { target: 'batchGetDropoffLocations' },
    },
    batchGetDropoffLocations: {
      description: '获取 dropoff method 下的 location',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'batchGetDropoffLocations',
            input: ({ context }) => ({
              token: context.token,
              orderId: context.orderId,
              slugs: getDropoffSlugs(context.returnMethods),
            }),
            onDone: {
              target: 'success',
              actions: assign({ dropoffLocations: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: {
        // 不能在一个 assign 中同时赋值 returnMethods 和 selectedMethodId
        // 因为这里需要先更新 returnMethods，再更新 selectedMethodId
        actions: [
          assign({
            returnMethods: ({ context }) => {
              // 没有可用的 dropoff locations 时，禁用 retail rework
              // 超过 9 个 items 时，禁用 happy returns
              return context.returnMethods
                .map((method) => {
                  if (
                    method.slug === ReturnMethodSlug.RetailRework &&
                    (context.dropoffLocations[ReturnMethodSlug.RetailRework]?.locations || [])
                      .length <= 0
                  ) {
                    return { ...method, disabled: true };
                  }
                  const count = context.selectedItems.reduce((acc, item) => acc + item.quantity, 0);
                  if (
                    method.slug === ReturnMethodSlug.HappyReturns &&
                    count > maxHappyReturnSelectedItems
                  ) {
                    return { ...method, disabled: true };
                  }
                  return method;
                })
                .sort((a, b) => {
                  // 都禁用时，retail rework 放在最下面
                  if (
                    a.disabled &&
                    b.disabled &&
                    b.slug === ReturnMethodSlug.RetailRework &&
                    a.slug === ReturnMethodSlug.HappyReturns
                  ) {
                    return -1;
                  }
                  if (!a.disabled && b.disabled) {
                    return -1;
                  }
                  return 1;
                });
            },
          }),
          assign({
            selectedMethodId: ({ context }) => {
              const method = context.returnMethods.find((method) => {
                if (method.id === context.selectedMethodId && !method.disabled) {
                  return true;
                }
                if (method.slug === ReturnMethodSlug.HappyReturns && !method.disabled) {
                  return true;
                }
                if (method.slug === ReturnMethodSlug.RetailRework && !method.disabled) {
                  return true;
                }
                return false;
              });
              return method ? method.id : '';
            },
          }),
        ],
        target: 'waitingSelectReturnMethod',
      },
    },
    loadNearbyLocations: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'getDropoffLocations',
            input: ({ context, event }) => {
              assertEvent(event, 'LOAD_NEARBY_LOCATIONS');
              return {
                token: context.token,
                orderId: context.orderId,
                latitude: event.data.latitude,
                longitude: event.data.longitude,
                slug: SupportDropoffSlug.HappyReturns,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({ nearbyLocations: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    waitingSelectReturnMethod: {},
    done: { type: 'final' },
  },
  on: {
    SELECT_RETURN_METHOD: {
      actions: assign({ selectedMethodId: ({ event }) => event.data.methodId }),
    },
    LOAD_NEARBY_LOCATIONS: { target: '.loadNearbyLocations' },
    NEXT: { target: '.done' },
  },
});

export type ReturnMethodSubFlowActorRef = ActorRefFrom<typeof returnMethodSubFlow>;
export type ReturnMethodSubFlowSnapshot = SnapshotFrom<typeof returnMethodSubFlow>;
