import { fromPromise } from 'xstate';

import { hostName, v4hostName } from '../constant/hostname';
import { DistanceUnit, SupportDropoffSlug } from '../constant/returnMethod';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import {
  BatchGetDropoffLocationsInput,
  DropoffLocation,
  DropoffLocations,
  GetDropoffLocationsInput,
  GetMatchingMethodInput,
  ReturnMethod,
} from '../types/returnMethod';
import { dropoffSlugToReturnMethodSlug, getLocationCountBySlug } from '../utils/returnMethod';

export const getMatchingMethod = fromPromise(
  async ({ input }: { input: GetMatchingMethodInput }) => {
    const { token, returnItems, refundDestination, exchangeItems, resolution } = input;
    return await request<ResponseBody<{ return_methods: ReturnMethod[] }>>(
      `${v4hostName()}/return-method-matchings`,
      {
        method: 'POST',
        headers: { 'returns-authorization': token },
        payload: {
          return_items: returnItems,
          refund_destination: refundDestination,
          exchange_items: exchangeItems,
          resolution,
        },
      },
    ).then((res) => res?.data?.return_methods ?? []);
  },
);

export const getReturnMethodsAll = fromPromise(
  async ({ input }: { input: { shopId: string; token: string } }) => {
    const { token, shopId } = input;
    return await request<ResponseBody<{ return_methods: ReturnMethod[] }>>(
      `${hostName()}/return-methods/all?shop_id=${shopId}`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    ).then((res) => res?.data?.return_methods ?? []);
  },
);

export const getReturnMethodById = fromPromise(
  async ({ input }: { input: { token: string; returnMethodId: string } }) => {
    const { token, returnMethodId } = input;
    return await request<ResponseBody<ReturnMethod>>(
      `${v4hostName()}/return-methods/${returnMethodId}`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    ).then((res) => res?.data || Promise.reject(res.data));
  },
);

export const batchGetDropoffLocations = fromPromise(
  async ({ input }: { input: BatchGetDropoffLocationsInput }) => {
    const { token, slugs, orderId } = input;

    const requests = slugs.map((slug) => {
      const distanceUnit =
        slug === SupportDropoffSlug.HappyReturns ? DistanceUnit.MI : DistanceUnit.KM;
      const count = getLocationCountBySlug(slug);
      return request<ResponseBody<DropoffLocation>>(
        `${v4hostName()}/dropoff-locations?distance_unit=${distanceUnit}&slug=${slug}&order_id=${orderId}&count=${count}`,
        { method: 'GET', headers: { 'returns-authorization': token } },
      ).then((res) => ({ slug, data: res.data }));
    });

    return (await Promise.all(requests)).reduce<DropoffLocations>((prev, cur) => {
      prev[dropoffSlugToReturnMethodSlug(cur.slug)] = cur.data;
      return prev;
    }, {});
  },
);

export const getDropoffLocations = fromPromise(
  async ({ input }: { input: GetDropoffLocationsInput }) => {
    const { token, slug, orderId, latitude, longitude } = input;
    const distanceUnit =
      slug === SupportDropoffSlug.HappyReturns ? DistanceUnit.MI : DistanceUnit.KM;

    const res = await request<ResponseBody<DropoffLocation>>(
      `${v4hostName()}/dropoff-locations?distance_unit=${distanceUnit}&slug=${slug}&order_id=${orderId}&latitude=${latitude}&longitude=${longitude}`,
      { method: 'GET', headers: { 'returns-authorization': token } },
    );
    return res?.data;
  },
);
