import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { OrderLookupType } from '../machine/orderLookupSubFlow/types';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { Session } from '../types/SessionInfo';

export type Payload = {
  orderNumber: string;
  email?: string;
  zipCode?: string;
  gReCaptchaToken?: string;
  orderLookupType: OrderLookupType;
  shopId: string;
  traceId?: string;
};

export const getSession = fromPromise(async ({ input }: { input: Payload }) => {
  const { email, orderNumber, shopId, zipCode, gReCaptchaToken, orderLookupType, traceId } = input;

  const additionalPayload =
    orderLookupType === OrderLookupType.EMAIL
      ? {
          customer_email: email,
        }
      : {
          postal_code: zipCode,
          recaptcha_response_token: gReCaptchaToken,
        };

  return request<ResponseBody<Session>>(`${v4hostName()}/sessions`, {
    method: 'POST',
    body: JSON.stringify({
      ...additionalPayload,
      order_number: orderNumber,
      shop: {
        id: shopId,
      },
      trace_id: traceId,
    }),
  }).then((response) => response.data);
});
