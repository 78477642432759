export enum GiftReturnResolution {
  storeCredit = 'store_credit',
  replaceWithTheSameItem = 'replace_with_the_same_item',
}

export type GiftReturnMatchingPayload = {
  order_number: string;
  postal_code: string;
  organization: {
    id: string;
  };
};
export type GiftReturnMatchingResponse = GiftReturnMatchingPayload;

export enum FormType {
  orderMatched = 'order_matched',
  orderUnknown = 'order_unknown',
}

export type GiftReturnFormPayload = {
  form_type: FormType;
  recipient: {
    first_name: string;
    last_name: string;
    email: string;
  };
  customer: {
    first_name: string;
    last_name: string;
    email?: string;
  };
  recipient_shipping_address?: {
    first_name?: string;
    last_name?: string;
    address_line_1?: string;
    address_line_2?: string;
    country?: string;
    state?: string;
    city?: string;
    postal_code?: string;
    phone_number?: string;
  };
  product_name: string;
  resolution: GiftReturnResolution;
  notes?: string;
  customer_lang?: string;
} & GiftReturnMatchingPayload;

export type GiftReturnFormResponse = GiftReturnFormPayload;
