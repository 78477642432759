export enum GrayFeatureKey {
  ShowExternalOrder = 'show_external_order',
  DisabledWholeReturnWhenPartialReturn = 'disabled_whole_return_when_partial_return',
  BlockSearchEngine = 'block_search_engine',
  ReplaceOnStore = 'replace_on_store',
  HiddenSummaryAndPriceAlways = 'hidden_summary_and_price_always',
  HiddenBundleProductItemPrice = 'hidden_bundle_product_item_price',
  EnabledGetAdditionalLabel = 'enabled_get_additional_label',
  DisabledLoopQueryLabelStatus = 'disabled_loop_query_label_status',
  ShowProductFullValuePrice = 'show_product_full_value_price',
  HiddenHappyReturnViewAllLocation = 'hidden_happy_return_view_all_location',
}

export enum GrayFeatureBEKey {}

export enum ReturnsPageAccessStatus {
  Granted = 'granted',
  Denied = 'denied',
}

export enum ReturnsPageAccessCode {
  ReturnsPageNotAvailable = 'returns_page_not_available',
  ReturnsPageNotPublished = 'returns_page_not_published',
  AppProxyNotAvailable = 'app_proxy_not_available',
  UnexpectedHostname = 'unexpected_hostname',
  ServerError = 'server_error',
  PageForbidden = 'page_forbidden',
}

export const SHOPPER_HOST_KEY = 'shopper-hostname';

export const SHOPPER_HOST_KEY_FROM_SEARCH = 'hostname';

// Block some sniff request, check it out for more details:
// https://grafana.automizely.org/d/-BMrSW24k/shopper-returnscenter-com?orgId=1&var-datasource=Prometheus-Prod-GKE-1&var-job=service-shopper-returnscenter-com&var-service=service-shopper-returnscenter-com&var-deployment=deployment-shopper-returnscenter-com&from=now-6h&to=now
export const PATH_BLOCK_LIST =
  /\.php|admin|\.xml|wp-|\.rar|\.zip|well-known|\.alfa|\.aws|\.env|ads\.txt|\.sh|\.github|grafana|pass|\.git|\.well-known/;

// Symbol like :;, would affect connector parsing query parrm of filter[]
export const symbolNotSupportedRegex = /[:;,&]+/;

export const emailRegex = /^(.+)@(.+)$/;

export const HOSTNAME_PORT_REGEX = /:\d+$/;

export enum EfaMethod {
  inStore = 'in_store',
  inApp = 'in_app',
}

export enum PayMethod {
  shopify = 'shopify_checkout',
  stripe = 'stripe',
}

export enum InstantExchangeHoldPaymentAmountType {
  oneDollar = 'one_dollar',
  fullReturnValue = 'full_return_value',
}

export enum InstantExchangeChargeTriggerStatus {
  trackingInTransit = 'tracking_in_transit',
  trackingDelivered = 'tracking_delivered',
  returnReceived = 'return_received',
}
