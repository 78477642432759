import {
  ItemResolution,
  RefundDestination,
  Resolution,
  ReturnMethodSlug,
  SupportDropoffSlug,
  defaultLocationCount,
  locationCountMapping,
} from '../constant';
import { SkuItem } from '../types/exchangeInApp';
import { ISelectedItem } from '../types/machine/ItemSelectionSubFlow';
import { ReturnMethod } from '../types/returnMethod';

export function getLocationCountBySlug(slug?: SupportDropoffSlug) {
  return slug ? locationCountMapping[slug] || defaultLocationCount : defaultLocationCount;
}

export function returnMethodSlugToDropoffSlug(
  slug: ReturnMethodSlug.HappyReturns | ReturnMethodSlug.RetailRework,
) {
  switch (slug) {
    case ReturnMethodSlug.HappyReturns:
      return SupportDropoffSlug.HappyReturns;
    case ReturnMethodSlug.RetailRework:
      return SupportDropoffSlug.RetailRework;
    default:
      return slug;
  }
}
export function dropoffSlugToReturnMethodSlug(slug: SupportDropoffSlug) {
  switch (slug) {
    case SupportDropoffSlug.HappyReturns:
      return ReturnMethodSlug.HappyReturns;
    case SupportDropoffSlug.RetailRework:
      return ReturnMethodSlug.RetailRework;
    default:
      return slug;
  }
}

export function getDropoffSlugs(methods: ReturnMethod[]): SupportDropoffSlug[] {
  return methods
    .map((method) => method.slug)
    .filter(
      (slug): slug is ReturnMethodSlug.HappyReturns | ReturnMethodSlug.RetailRework =>
        slug === ReturnMethodSlug.HappyReturns || slug === ReturnMethodSlug.RetailRework,
    )
    .map(returnMethodSlugToDropoffSlug);
}

export function resolutionToRefundDestination(resolution?: Resolution): RefundDestination | null {
  switch (resolution) {
    case Resolution.StoreCredit:
      return RefundDestination.StoreCredit;
    case Resolution.OriginalPayment:
      return RefundDestination.OriginalPayment;
    case Resolution.Refundid:
      return RefundDestination.Refundid;
    default:
      return null;
  }
}

export function parseMatchingMethodPayload({
  resolution,
  exchangeItems,
  selectedItems,
}: {
  resolution?: Resolution;
  exchangeItems: SkuItem[];
  selectedItems: ISelectedItem[];
}) {
  const isExchange = resolution === Resolution.ExchangeForAnything;
  const isReplace = resolution === Resolution.ReplaceTheSameItem;
  return {
    returnItems: selectedItems.map((item) => ({
      item_id: item.itemId,
      quantity: item.quantity,
      reason: item.selectedReason,
      subreason: item.selectedSubReason || item.selectedReason,
      resolution: isReplace ? ItemResolution.Exchange : ItemResolution.Refund,
      replacement:
        // todo: 这里逻辑有待确认
        isReplace && item.exchangeVariant
          ? {
              external_product_id: item.exchangeVariant?.productId ?? '',
              external_variant_id: item.exchangeVariant?.variantId ?? '',
            }
          : null,
    })),
    exchangeItems: isExchange ? exchangeItems : null,
    refundDestination: resolutionToRefundDestination(resolution),
    // RTC-23211: decisionMethodOrReviewActor 场景下请求时还没有 exchangeItems，拿不到 resolution 后端会默认为 replace
    // 这里强制指定 resolution，后面三期支持多 resolution 后需要修改
    resolution,
  };
}
