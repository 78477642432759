export enum ShopifyFeatureTag {
  ConsentTracking = 'consent-tracking-api',
}

export const shopifyFeaturesMap: Record<ShopifyFeatureTag, ILoadShopifyFeaturePayload> = {
  [ShopifyFeatureTag.ConsentTracking]: {
    name: ShopifyFeatureTag.ConsentTracking,
    version: '0.1',
  },
};

export const loadShopifyFeatures = (
  fetures: ILoadShopifyFeaturePayload[],
  callback: ILoadShopifyFeatureCallback,
) => {
  if (!window?.Shopify?.loadFeatures) {
    console.warn('loadShopifyFeatures should be invoked in shopify storefront');
    return;
  }

  window.Shopify.loadFeatures(fetures, callback);
};
