import { useCallback, useState } from 'react';

type UseUpload<T = any> = {
  action: UploadAction;
  limitSize: number;
  onUploaded?: (uploaders: Uploader<T>[]) => void;
  onError?: (error: UploadError) => void;
  files?: string[];
  allowSameFile?: boolean;
};

type Put = (url: string, body: Omit<PutOptions, 'key'>) => Promise<any>;

export type UploadAction<T = any> =
  | string
  | ((put: Put, file: File, abortSignal?: AbortSignal) => Promise<T>);
export type UploadError = {
  type: 'request' | 'overSize' | 'limit-length' | 'non-repeatable';
  error?: Error;
};
export type Uploader<T = any> = {
  file: File;
  /**
   * Thumbnail of the file
   */
  thumbnail: string;
  /**
   * Hash of the file, genrated by client temporarily
   */
  key: string;
  status: 'error' | 'uploading' | 'done';
  progress: number;
  uploadError?: UploadError;
  /**
   * URL of the file uploaded successfully
   */
  url?: string;
  response: T;
  abortController?: AbortController;
};

type PutOptions = {
  method?: string;
  data: File;
  headers?: Record<string, string>;
  onProgress?: (progress: number) => void;
  contentType?: string;
  key: string;
};

interface PutFn {
  (url: string, options: PutOptions): Promise<any>;
  [key: string]: {
    abort: () => void;
  };
}

type CheckFileArgs = {
  fileHash: string;
  uploaders: Uploader[];
  payload: UseUpload<any>;
};

// @ts-ignore
const put: PutFn = (url: string, options: PutOptions): Promise<any> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    // store the xhr instance for aborting
    // FIXME: This is not a good practice to store the xhr instance in the global scope
    // It may cause memory leak if the xhr is not aborted properly

    put[options.key] = {
      abort: () => xhr.abort(),
    };

    const reader = new FileReader();
    const { data: file, onProgress, contentType, method = 'POST', headers } = options;

    xhr.upload.addEventListener('progress', (evt) => {
      if (evt.lengthComputable) {
        const progress = Math.round((evt.loaded * 100) / evt.total);
        onProgress?.(progress);
      }
    });

    xhr.addEventListener('load', () => {
      onProgress?.(100);
      if (xhr.status < 400) {
        resolve(xhr.responseText);
      } else {
        reject(`${xhr.status}: ${xhr.statusText}`);
      }
    });

    xhr.addEventListener('error', () => {
      reject(xhr.response);
    });

    xhr.addEventListener('abort', () => {
      reject('Request aborted');
    });

    reader.onload = (evt) => {
      if (evt.target) {
        xhr.send(evt.target.result);
      }
    };
    xhr.open(method, url);
    xhr.setRequestHeader('Content-Type', contentType || file.type || 'application/octet-stream');
    // set custom header
    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key]);
    }
    reader.readAsArrayBuffer(file);
  });
};

const checkFile = (file: File, { uploaders, payload, fileHash }: CheckFileArgs) => {
  const { limitSize, onError, allowSameFile } = payload;
  const fileSizeInMB = file.size / 1024 / 1024;
  const isOverSize = fileSizeInMB > limitSize;
  const result = {
    isBreak: false,
  } as {
    isBreak: boolean;
    error?: UploadError;
  };
  if (isOverSize) {
    result.error = {
      type: 'overSize',
      error: new Error(`File size exceed the limit size: ${limitSize} MB.`),
    } as UploadError;
    onError?.(result.error);
    result.isBreak = true;
  }
  if (!allowSameFile) {
    const isExist = uploaders.some((uploader) => uploader.key === fileHash);
    if (isExist) {
      result.isBreak = true;
      result.error = {
        type: 'non-repeatable',
        error: new Error(`Duplicate files are not currently allowed to be uploaded`),
      } as UploadError;
      onError?.(result.error);
    }
  }
  return result;
};

const useUpload = <T extends any>(payload: UseUpload<T>) => {
  const { action, onUploaded, files = [], onError, allowSameFile } = payload;
  // init from external files for preview and further upload
  const [uploaders, setUploaders] = useState<Uploader[]>(createInitialUploaders(files));
  const upload = async (file: File) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fileHash = allowSameFile === true ? crypto.randomUUID() : await generateFileHash(file);
    const { error, isBreak } = checkFile(file, { fileHash, payload, uploaders });
    if (isBreak) {
      return;
    }

    const uploader: Uploader = {
      file,
      thumbnail: URL.createObjectURL(file),
      key: fileHash,
      status: error ? 'error' : 'uploading',
      progress: 0,
      response: '',
      uploadError: error,
      abortController,
    };

    const onProgress = (progress: number) => {
      // update the progress of the uploader
      setUploaders((u) => createOrUpdate(u, { ...uploader, progress }));
    };
    // Append a new uploader
    setUploaders((u) => {
      const uploaders = createOrUpdate(u, uploader);
      onUploaded?.(uploaders);
      return uploaders;
    });

    try {
      if (typeof action === 'string') {
        uploader.response = await put(action, {
          data: file,
          onProgress,
          key: fileHash,
        });
      } else {
        uploader.response = await action(
          (url: string, options) =>
            put(url, {
              ...options,
              onProgress,
              key: fileHash,
            }),
          file,
          signal,
        );
      }
      // Update the uploader status to done
      setUploaders((u) => {
        const uploaders = createOrUpdate(u, { ...uploader, status: 'done' });
        delete put[uploader.key];
        onUploaded?.(uploaders);
        return uploaders;
      });
    } catch (error: any) {
      if (typeof error === 'string' && error === 'Request aborted') {
        // 只有在请求发起后 cancel 才会进入这里
        // 如果在 generateFileHash 之前 abort，不会进入这里，所以还需要额外处理 generateFileHash 之前 cancel 的情况
        // todo: handle aborted error
        return;
      }
      const requestError = {
        type: 'request',
        error,
      } as const;

      onError?.(requestError);

      // Update the uploader status to error
      setUploaders((u) => {
        const uploaders = createOrUpdate(u, {
          ...uploader,
          status: 'error',
          uploadError: requestError,
        });
        delete put[uploader.key];
        onUploaded?.(uploaders);
        return uploaders;
      });
    }
  };

  const remove = useCallback(
    (key: string) => {
      setUploaders((u) => {
        const uploaders = u.filter((uploader) => uploader.key !== key);
        const currentUploader = u.find((uploader) => uploader.key === key);
        currentUploader?.abortController?.signal?.aborted === false &&
          currentUploader?.abortController?.abort?.();
        onUploaded?.(uploaders);
        // @ts-ignore
        put[key]?.abort?.();
        return uploaders;
      });
    },
    [onUploaded],
  );

  return { upload, remove, uploaders };
};

const createOrUpdate = (uploaders: Uploader[] = [], uploader: Partial<Uploader>) => {
  const index = uploaders.findIndex((u) => u.key === uploader.key);
  if (index === -1) {
    return [...uploaders, uploader] as Uploader[];
  }
  return uploaders.map((u, i) => (i === index ? { ...u, ...uploader } : u)) as Uploader[];
};

const generateFileHash = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const hash = await crypto.subtle.digest('SHA-256', arrayBuffer);
  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hash));
  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

const createInitialUploaders = (files: string[]): Uploader[] => {
  return files.map((file) => ({
    file: new File([], file),
    thumbnail: file,
    key: file,
    status: 'done',
    progress: 100,
    response: '',
  }));
};

export default useUpload;
