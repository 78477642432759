import { Preview, ReturnCostOption, SummaryTotalType } from '../../../core';
import { mockReplaceItemId, mockSelectedProductId } from '../order/defaultOrder';
import { MOCK_SHIPPING_ADDRESS } from '../returns/defaultReturns';

const defaultMoney = {
  amount: '0.00',
  currency: 'USD',
};

const defaultReturnCredit = {
  amount: '100.00',
  currency: 'USD',
};

const defaultCostOfReturn = {
  amount: '10.00',
  currency: 'USD',
};
export const defaultPreview: Preview = {
  id: 'b96c7fcc26d14d2eba296877c94c11cc',
  organization: {
    id: '251aabe48d9f45a3975d8413eeb77001',
  },
  order: {
    id: 'ebb4a8c3711c4dcf8faa7eee3ccfaeec',
  },
  return_items: [
    {
      quantity: 1,
      item_id: mockReplaceItemId,
      replacement: {
        external_product_id: mockSelectedProductId,
        external_variant_id: '43159344578814',
        price_set: {
          shop_money: null,
          presentment_money: {
            amount: '210.00',
            currency: 'USD',
          },
        },
      },
    },
  ],
  return_method: {
    id: '',
    cost_of_return: defaultMoney,
    cost_of_return_context: { cost_of_return_setting_id: '' },
  },
  resolution: '',
  gift_return: null,
  cost_of_return_should_charge: false,
  exchange_items: [],
  created_at: '2024-07-22T12:22:39Z',
  instant_exchange: {
    available: false,
  },
  exchange_shipping_address: {
    first_name: MOCK_SHIPPING_ADDRESS,
  },
  summary: {
    return_cost_option: ReturnCostOption.CUSTOM,
    credit_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultReturnCredit,
    },
    refund_credit_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    return_item_subtotal_set: {
      shop_money: defaultMoney,
      presentment_money: defaultReturnCredit,
    },
    return_item_tax_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    return_item_taxes_included: false,
    extra_credit_value_option: null,
    extra_credit_value: null,
    extra_credit_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    extra_credit_used_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    pre_discount_credit_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    pre_discount_credit_used_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    credit_remain_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    exchange_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    exchange_item_subtotal_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    exchange_item_tax_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    exchange_item_taxes_included: false,
    cost_of_return_set: {
      shop_money: defaultMoney,
      presentment_money: defaultCostOfReturn,
    },
    exchange_shipping_fee_option: null,
    exchange_shipping_fee_set: {
      shop_money: defaultMoney,
      presentment_money: null,
    },
    checkout_total_rounding_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    financial_outcome: SummaryTotalType.Unchanged,
    checkout_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    refund_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    checkout_custom_shipping_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
    checkout_discount_total_set: {
      shop_money: defaultMoney,
      presentment_money: defaultMoney,
    },
  },
};
