import * as yup from 'yup';

import { GiftReturnResolution } from '../../types';
import { isEmail } from '../../utils/validation';

export const addressSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  line1: yup.string().required('Address line 1 is required'),
  line2: yup.string(),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  postalCode: yup.string().required('Postal code is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  state: yup.string().when(['country', '$countryMap'], ([country, countryMap], schema) => {
    if (countryMap && countryMap[country]?.sublist?.length > 0) {
      return schema.required('State is required when country has sublist');
    }
    return schema.notRequired();
  }),
});

const userSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required().test({
    test: isEmail,
    message: 'Invalid email',
    name: 'email',
  }),
});

const customerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().notRequired().test({
    test: isEmail,
    message: 'Invalid email',
    name: 'email',
  }),
});

export const NotMatchingOrderSchema = yup.object({
  productName: yup.string().strict().required('Product name is required'),
  resolution: yup.mixed<GiftReturnResolution>().oneOf(Object.values(GiftReturnResolution)),
  option: yup.string().when('resolution', {
    is: GiftReturnResolution.replaceWithTheSameItem,
    then: (schema) =>
      schema.required('Option is required when resolution is replaceWithTheSameItem'),
    otherwise: (schema) => schema.notRequired(),
  }),
  notes: yup.string().notRequired(),

  address: yup.object().when('resolution', ([resolution]) => {
    if (resolution === GiftReturnResolution.replaceWithTheSameItem) {
      return addressSchema;
    }
    return yup.object().notRequired();
  }) as yup.ObjectSchema<AddressType>,
  recipient: userSchema.notRequired(),
  customer: customerSchema.notRequired(),
  langCode: yup.string().notRequired(),
});

export const MatchingOrderSchema = yup.object({
  productName: yup.string().strict().required('Product name is required'),
  resolution: yup.mixed<GiftReturnResolution>().oneOf(Object.values(GiftReturnResolution)),
  option: yup.string().when('resolution', {
    is: GiftReturnResolution.replaceWithTheSameItem,
    then: (schema) =>
      schema.required('Option is required when resolution is replaceWithTheSameItem'),
    otherwise: (schema) => schema.notRequired(),
  }),
  notes: yup.string().notRequired(),
  address: yup.object().when('resolution', ([resolution]) => {
    if (resolution === GiftReturnResolution.replaceWithTheSameItem) {
      return addressSchema;
    }
    return yup.object().notRequired();
  }) as yup.ObjectSchema<AddressType>,
  recipient: userSchema.notRequired(),
  langCode: yup.string().notRequired(),
});

export const finOrderSchmea = yup.object({
  orderNumber: yup.string().required('Order number is required'),
  zipCode: yup.string().required('Zip code is required'),
});

export type AddressType = yup.InferType<typeof addressSchema>;
