import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  EventName,
  PayloadInUpdateView,
  PreviewMessageData,
  UpdatePreviewDataType,
  UpdateViewType,
} from '@aftership/preview-kit/business/rc';
import { messageBridge, usePreviewContext } from '@aftership/preview-kit/client';
import { IClickwrap } from 'returns-logics';
import { useClickwrap } from 'returns-logics/react';

export interface IClickwrapContext {
  clickwrapConfig?: IClickwrap;
  isClickwrapChecked: boolean;
  setClickwrapChecked: Dispatch<SetStateAction<boolean>>;
  warningMsgVisible: boolean;
  setWarningMsgVisible: Dispatch<SetStateAction<boolean>>;
  isClickwrapPopupOpen: boolean;
  setClickwrapPopupOpen: Dispatch<SetStateAction<boolean>>;
  shouldCheckClickwrap: boolean;
  setShouldIgnoreClickwrap: (value: boolean) => void;
}

export const ClickwrapContext = React.createContext<IClickwrapContext>({
  isClickwrapChecked: false,
  warningMsgVisible: false,
  shouldCheckClickwrap: false,
} as IClickwrapContext);

export const useClickwrapContext = () => {
  return useContext(ClickwrapContext);
};

export interface IClickwrapProviderProps {
  children: React.ReactNode;
}

const ClickwrapProvider = ({ children }: IClickwrapProviderProps) => {
  const { isPreview } = usePreviewContext();

  const {
    context: { clickwrapData },
    ref,
  } = useClickwrap(isPreview);

  const [isClickwrapChecked, setClickwrapChecked] = useState(!!clickwrapData?.checked_by_default);
  const [warningMsgVisible, setWarningMsgVisible] = useState(false);
  const [isClickwrapPopupOpen, setClickwrapPopupOpen] = useState(false);
  const shouldIgnoreClickwrapRef = useRef<boolean>(false);

  useEffect(() => {
    setClickwrapChecked(!!clickwrapData?.checked_by_default);
  }, [clickwrapData?.checked_by_default]);

  useEffect(() => {
    const removeEventListener = messageBridge.onMessage({
      type: EventName.UpdateView,
      callback: (data: PreviewMessageData[EventName.UpdateView]) => {
        if (data.type === UpdateViewType.ToggleClickwrapModal) {
          setClickwrapPopupOpen(
            (data.payload as PayloadInUpdateView['toggle_clickwrap_modal']).visible,
          );
        }
      },
    });
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const removeEventListener = messageBridge.onMessage({
      type: EventName.UpdatePreviewData,
      callback: (data: PreviewMessageData[EventName.UpdatePreviewData]) => {
        if (data.type === UpdatePreviewDataType.UpdateClickWrap) {
          ref.send({
            type: 'UPDATE_MOCK_CLICKWRAP',
            data: {
              clickwrapData: data.payload as IClickwrap,
            },
          });
        }
      },
    });
    return () => {
      removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setShouldIgnoreClickwrap = useCallback((shouldIgnore: boolean) => {
    shouldIgnoreClickwrapRef.current = shouldIgnore;
  }, []);

  const shouldCheckClickwrap = useMemo(() => {
    return Boolean(
      clickwrapData?.feature_applied &&
        clickwrapData.enabled &&
        !isClickwrapChecked &&
        !shouldIgnoreClickwrapRef.current,
    );
  }, [clickwrapData, isClickwrapChecked]);

  const contextValue = useMemo(() => {
    return {
      clickwrapConfig: clickwrapData,
      isClickwrapChecked,
      setClickwrapChecked,
      warningMsgVisible,
      setWarningMsgVisible,
      isClickwrapPopupOpen,
      setClickwrapPopupOpen,
      shouldCheckClickwrap,
      setShouldIgnoreClickwrap,
    };
  }, [
    clickwrapData,
    isClickwrapChecked,
    setClickwrapChecked,
    warningMsgVisible,
    setWarningMsgVisible,
    isClickwrapPopupOpen,
    setClickwrapPopupOpen,
    shouldCheckClickwrap,
    setShouldIgnoreClickwrap,
  ]);

  return <ClickwrapContext.Provider value={contextValue}>{children}</ClickwrapContext.Provider>;
};

export default ClickwrapProvider;
