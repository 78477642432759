import dynamic from 'next/dynamic';
import { PropsWithChildren, useEffect, useState } from 'react';

import {
  EventName,
  PayloadInUpdatePreviewData,
  PreviewMessageData,
  UpdatePreviewDataType,
} from '@aftership/preview-kit/business/rc';
import { PreviewProvider, messageBridge } from '@aftership/preview-kit/client';

import MetaFonts from './MetaFonts';

export interface PreviewEntryProps {
  isPreview: boolean;
}

const DynamicPreviewContainer = dynamic(
  () => import('@/features/preview/components/PreviewContainer'),
  {
    ssr: true,
    loading: () => <div>Loading...</div>,
  },
);

const PreviewEntry = ({ children, isPreview }: PropsWithChildren<PreviewEntryProps>) => {
  if (!isPreview) {
    return (
      <PreviewProvider isPreview={isPreview}>
        {/** @ts-ignore */}
        {children}
      </PreviewProvider>
    );
  }

  return (
    <PreviewProvider isPreview={isPreview}>
      <PreviewDynamicWrapper />
      <DynamicPreviewContainer>{children}</DynamicPreviewContainer>
    </PreviewProvider>
  );
};

const PreviewDynamicWrapper = () => {
  const [fonts, setFonts] = useState<string[]>([]);

  useEffect(() => {
    messageBridge.onMessage({
      type: EventName.UpdatePreviewData,
      callback: (data: PreviewMessageData[EventName.UpdatePreviewData]) => {
        if (data.type === UpdatePreviewDataType.UpdateShopTheme) {
          const typePayload =
            data.payload as PayloadInUpdatePreviewData[UpdatePreviewDataType.UpdateShopTheme];
          setFonts([typePayload.primary_font, typePayload.body_font]);
        }
      },
    });
  }, []);
  return <MetaFonts fontFamilies={fonts} />;
};

export default PreviewEntry;
