import React, { ReactNode } from 'react';

import { Box, BoxProps, DimensionValue } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import * as styles from './ScrollBox.css';

const ScrollBarWidth = SpacingVars['1'];
const token2ClassName = (token?: DimensionValue) => {
  switch (token) {
    case SpacingVars['12']:
      return styles.m48;
    case SpacingVars['10']:
      return styles.m40;
    case SpacingVars['6']:
      return styles.m24;
    case SpacingVars['4']:
      return styles.m16;
    default:
      return '';
  }
};

interface ScrollBoxProps extends BoxProps {
  children: ReactNode;
  /**
   * @default 移动端: SpacingVars[10] 其他: SpacingVars[4]
   */
  offsetY?: DimensionValue;
  /**
   * @default 移动端: SpacingVars[1] 其他: SpacingVars[2]
   */
  offsetX?: DimensionValue;
  innerProps?: BoxProps;
}

const ScrollBox = ({
  offsetY,
  offsetX,
  padding,
  paddingY,
  paddingTop,
  paddingBottom,
  paddingX,
  paddingStart,
  paddingEnd,
  children,
  innerProps,
  ...props
}: ScrollBoxProps) => {
  const isMobile = useDevice().mobile;
  const defaultOffsetY = isMobile ? SpacingVars[10] : SpacingVars[4];
  const defaultOffsetX = isMobile ? SpacingVars[1] : SpacingVars[2];
  const innerMargin = token2ClassName(offsetY || defaultOffsetY);
  const outerPaddingX = offsetX || defaultOffsetX;
  const start = `calc(${paddingStart || paddingX || padding || 0} - ${outerPaddingX} - ${ScrollBarWidth})`;
  const end = `calc(${paddingEnd || paddingX || padding || 0} - ${outerPaddingX} - ${ScrollBarWidth})`;
  const top = paddingTop || paddingY || padding || 0;
  const bottom = paddingBottom || paddingY || padding || 0;

  return (
    <Box paddingX={outerPaddingX} className={styles.outerContainer} {...props}>
      <Box
        paddingStart={start}
        paddingEnd={end}
        paddingTop={top}
        paddingBottom={bottom}
        className={`${styles.innerContainer} ${innerMargin}`}
        {...innerProps}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ScrollBox;
