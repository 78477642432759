import React from 'react';

export interface MetaFontsProps {
  fontFamilies: string[];
}

const MetaFonts = (props: MetaFontsProps) => {
  const { fontFamilies } = props;
  const deduplicatedFonts = [...new Set(fontFamilies)];
  return (
    <>
      {deduplicatedFonts.map((font) => (
        <link
          key={font}
          rel='stylesheet'
          href={`https://fonts.googleapis.com/css2?family=${font}&display=swap`}
        />
      ))}
    </>
  );
};

export default MetaFonts;
