export const RESOLUTIONT_MAP = {
  STORE_CREDIT: 'store_credit',
  ORIGINAL_PAYMENT: 'original_payment',
  EXCHANGE: 'exchange',
  EXCHANGE_FOR_ANYTHING: 'exchange_for_anything',
  REFUNDID: 'refundid',
};

export enum SummaryTotalType {
  Unchanged = 'unchanged',
  Upsell = 'upsell',
  Refund = 'refund',
}

export enum AutoLabelStatus {
  Crasting = 'creating',
  Created = 'created',
  Failed = 'failed',
}
