/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  raise,
  sendParent,
  sendTo,
  setup,
} from 'xstate';

import {
  Events,
  PreviewExchangeItem,
  ReviewInput,
  ReviewMachineContext,
  ReviewMachineOutput,
} from './type';

import { ErrorResponse } from '../../../core/request/ErrorResponse';
import { ItemResolution, Resolution } from '../../constant';
import { getReturnById, updateIntentionData } from '../../promise';
import { getMatchingResolutions } from '../../promise/resolution';
import {
  deleteDraftReturnsActor,
  getDraftReturnByIdActor,
  getDraftReturnsActor,
  postReturnPreviewActor,
  postReturnsActor,
} from '../../promise/review';
import { EfaMethod, RefundDestinationType, ReturnItemsPayload } from '../../types';
import { IReturnIntentions } from '../../types/intention';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { resolveIntentionData } from '../resolveIntentionData';

const POLLING_MAX_COUNT = 10;
const MAX_DELAY = 1 * 10_000;

export const resolutionIsRefund = (
  resolution?: Resolution,
): resolution is RefundDestinationType => {
  return !!(
    resolution &&
    (resolution === Resolution.OriginalPayment ||
      resolution === Resolution.StoreCredit ||
      resolution === Resolution.Refundid)
  );
};

export const reviewSubFlow = setup({
  actors: {
    postReturnPreviewActor,
    postReturnsActor,
    getDraftReturnsActor,
    deleteDraftReturnsActor,
    getReturnById,
    getDraftReturnByIdActor,
    getMatchingResolutions,
    updateIntentionData,
    resolveIntentionData,
  },
  actions: {
    updateContextForOriginalPaymentInPreview: () => {},
    updateContextForExchangeInPreview: () => {},
    updateReviewContextInPreviewMode: () => {},
  },
  types: {
    context: {} as ReviewMachineContext,
    events: {} as Events,
    input: {} as ReviewInput,
    output: {} as ReviewMachineOutput,
  },
  guards: {
    isStopPolling: ({ context }) => {
      return context.pollCount >= POLLING_MAX_COUNT || !!context?.draftReturn?.return_id;
    },
    isDraftId: ({ context }) => {
      return !!context?.draftId;
    },
    isEFAonStore: ({ context }) => {
      return !!(
        context?.resolution &&
        context.resolution === Resolution.ExchangeForAnything &&
        context.efaMethod === EfaMethod.inStore
      );
    },
    isValidateData: ({ context }) => {
      return !!context?.selectedItems?.length && !!context?.resolution && !!context?.returnMethod;
    },
  },
  delays: {
    timeout: ({ context }) => {
      return Math.min(1000 + 200 * 2 ** context.pollCount, MAX_DELAY);
    },
  },
}).createMachine({
  initial: 'validateData',
  entry: ['updateReviewContextInPreviewMode'],
  context: ({ input }) => {
    return {
      ...input,
      pollCount: 0,
      exchangeItems:
        input?.exchangeItems?.map((exchangeItem) => {
          const hidePriceAndSummaryAlways = input.grayFeature.hiddenSummaryAndPriceAlways;
          return {
            ...exchangeItem,
            price: hidePriceAndSummaryAlways ? null : exchangeItem.price,
          };
        }) ?? [],
    };
  },
  output: ({ context }) => {
    return {
      rmaId: context.rmaId!,
    };
  },
  states: {
    validateData: {
      always: [
        {
          guard: 'isValidateData',
          target: 'exchangeCalculation',
        },
        {
          actions: sendParent(() => ({
            type: 'GO_TO_ORDER_LOOKUP',
          })),
        },
      ],
    },
    exchangeCalculation: {
      initial: 'decision',
      states: {
        decision: {
          always: [
            {
              guard: 'isEFAonStore',
              target: 'loading',
            },
            {
              target: 'done',
            },
          ],
        },
        loading: {
          tags: 'loading',
          invoke: {
            id: 'resolveIntentionData',
            src: 'resolveIntentionData',
            input: ({ context }) => {
              return {
                intentionId: context.intentionId,
                token: context.token,
              };
            },
            onDone: {
              target: 'success',
              actions: [
                assign({
                  exchangeItems: ({ event, context }) => {
                    const hidePriceAndSummaryAlways =
                      context.grayFeature?.hiddenSummaryAndPriceAlways;
                    return event.output.exchangeItems?.map((exchangeItem) => ({
                      ...exchangeItem,
                      price: hidePriceAndSummaryAlways ? null : exchangeItem.price,
                    }));
                  },
                  intentionData: ({ event }) => {
                    return event.output.intentionData;
                  },
                }),
              ],
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
        done: {
          type: 'final',
        },
      },
      onDone: {
        target: 'fetchReviewDatas',
      },
    },
    fetchReviewDatas: {
      type: 'parallel',
      states: {
        getMatchingResolutions: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getMatchingResolutions',
                input: ({ context }) => ({
                  token: context?.token!,
                  payload: {
                    returnItems: context.selectedItems.map<ReturnItemsPayload>((item) => ({
                      item_id: item.itemId,
                      quantity: item.quantity,
                      reason: item.selectedReason,
                      subreason: item.selectedSubReason ?? '',
                    })),
                    isGiftReturn: !!context?.isGiftReturn,
                  },
                }),
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
                onDone: {
                  target: 'success',
                  actions: assign(({ context, event }) => {
                    const appliedResolutions =
                      event.output.applied_resolutions?.filter(
                        (item) =>
                          !context.resolutionWhiteList?.length ||
                          context.resolutionWhiteList.includes(item),
                      ) ?? [];
                    return {
                      appliedResolutions,
                    };
                  }),
                },
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        postReturnPreviewActor: {
          id: 'postReturnPreview',
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'postReturnPreviewActor',
                src: 'postReturnPreviewActor',
                input: ({ context }) => {
                  return {
                    token: context?.token,
                    payload: {
                      order: {
                        id: context.orderId,
                      },
                      return_items:
                        context.selectedItems?.map((item) => ({
                          item_id: item.itemId,
                          quantity: item.quantity,
                          resolution:
                            context.resolution === Resolution.ReplaceTheSameItem
                              ? ItemResolution.Exchange
                              : ItemResolution.Refund,
                          ...(item.exchangeVariant
                            ? {
                                replacement: {
                                  external_product_id: item.exchangeVariant?.productId,
                                  external_variant_id: item.exchangeVariant?.variantId,
                                },
                              }
                            : {}),
                        })) ?? [],
                      return_method: {
                        id: context?.returnMethod?.id!,
                      },
                      ...(context?.exchangeItems?.length
                        ? {
                            exchange_items: context.exchangeItems?.map((item) => ({
                              external_product_id: item.productId,
                              external_variant_id: item.variantId,
                              quantity: item.quantity,
                            })),
                          }
                        : {}),
                      gift_return: {
                        is_gift_return: !!context?.isGiftReturn,
                        recipient_email: context?.giftReturnShippingAddress?.email ?? null,
                        recipient_first_name:
                          context?.giftReturnShippingAddress?.first_name ?? null,
                        recipient_last_name: context?.giftReturnShippingAddress?.last_name ?? null,
                        recipient_shipping_address: context?.giftReturnRecipientAddress ?? null,
                      },
                    },
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    preview: ({ event, context }) => {
                      const hidePriceAndSummaryAlways =
                        context.grayFeature?.hiddenSummaryAndPriceAlways;
                      const preview = event.output;
                      preview.return_items?.forEach(
                        (item) =>
                          item?.replacement &&
                          (item.replacement.price_set.presentment_money = hidePriceAndSummaryAlways
                            ? null
                            : item.replacement.price_set.presentment_money),
                      );
                      return preview;
                    },
                    allowUseInstantExchange: ({ event }) => event.output.instant_exchange.available,
                    instantExchange: ({ context }) => {
                      return !context?.allowUseInstantExchange
                        ? Boolean(context?.allowUseInstantExchange)
                        : Boolean(context?.instantExchange);
                    },
                    exchangeItems: ({ event, context }) => {
                      const exchangeItems = context?.exchangeItems ?? [];
                      const hidePriceAndSummaryAlways =
                        context.grayFeature?.hiddenSummaryAndPriceAlways;
                      return (
                        event?.output?.exchange_items?.map((item) => ({
                          ...exchangeItems?.find((i) => i.variantId === item.external_variant_id),
                          productId: item.external_product_id,
                          variantId: item.external_variant_id,
                          quantity: item.quantity,
                          price: hidePriceAndSummaryAlways
                            ? null
                            : item.price_set.presentment_money,
                        })) ||
                        exchangeItems ||
                        []
                      );
                    },
                    shippingAddress: ({ event }) => event.output?.exchange_shipping_address,
                    reviewError: () => null,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                    actions: assign({
                      reviewError: ({ event }) => {
                        return event.error as ErrorResponse;
                      },
                    }),
                  },
                ],
              },
            },
            success: {
              tags: 'success',
              type: 'final',
            },
            error: {
              tags: 'error',
            },
          },
        },
      },
    },
    postReturnsActor: {
      id: 'postReturns',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'postReturnsActor',
            src: 'postReturnsActor',
            input: ({ context, event }) => {
              assertEvent(event, 'SUBMIT_GENARATE_RETURN');
              const payload = event?.data;

              return {
                token: context?.token,
                payload: {
                  ...payload,
                  order: {
                    id: context?.orderId,
                  },
                  organization: {
                    id: context?.orgId,
                  },
                  return_items: context?.selectedItems?.map((item) => ({
                    item_id: item.itemId,
                    quantity: item.quantity,
                    reason: item.selectedReason,
                    subreason: item.selectedSubReason,
                    notes_to_merchant: item.comment,
                    return_images: item?.returnImages,
                    resolution:
                      context.resolution === Resolution.ReplaceTheSameItem
                        ? ItemResolution.Exchange
                        : ItemResolution.Refund,
                    ...(item.exchangeVariant
                      ? {
                          replacement: {
                            external_product_id: item.exchangeVariant?.productId,
                            external_variant_id: item.exchangeVariant?.variantId,
                          },
                        }
                      : {}),
                  })),
                  // TODO 灰度策略，第一期是 rma 纬度，后续是 item 纬度
                  resolution_granularity: 'rma',
                  return_method: {
                    id: context?.returnMethod?.id,
                  },
                  instant_exchange: {
                    is_instant_exchange: !!context?.instantExchange,
                  },
                  ...(context?.exchangeRequest
                    ? {
                        exchange_request: context.exchangeRequest,
                      }
                    : {}),
                  ...(context?.exchangeItems?.length
                    ? {
                        exchange_items: context.exchangeItems?.map((item) => ({
                          external_product_id: item.productId,
                          external_variant_id: item.variantId,
                          quantity: item.quantity,
                        })),
                      }
                    : {}),
                  ...(context?.refundDestination
                    ? { refund_destination: context.refundDestination }
                    : resolutionIsRefund(context.resolution)
                      ? {
                          refund_destination: context.resolution,
                        }
                      : {}),
                  ...(context?.filedBy
                    ? {
                        filed_by: context.filedBy,
                      }
                    : {}),
                  gift_return: {
                    is_gift_return: !!context?.isGiftReturn,
                    recipient_email: context?.giftReturnShippingAddress?.email ?? null,
                    recipient_first_name: context?.giftReturnShippingAddress?.first_name ?? null,
                    recipient_last_name: context?.giftReturnShippingAddress?.last_name ?? null,
                    recipient_shipping_address: context?.giftReturnRecipientAddress ?? null,
                  },
                  ...(context?.preview?.exchange_shipping_address
                    ? {
                        exchange_shipping_address: context?.preview?.exchange_shipping_address,
                      }
                    : {}),
                  ...(context?.preview?.summary?.cost_of_return_set?.presentment_money
                    ? {
                        cost_of_return:
                          context?.preview?.summary?.cost_of_return_set?.presentment_money,
                      }
                    : {}),
                },
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                returns: ({ event }) => event.output,
                rmaId: ({ event }) => event.output?.rma_id,
                draftId: ({ event }) => event.output?.draft_return?.id,
                returnError: () => null,
              }),
            },

            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
                actions: assign({
                  returnError: ({ event }) => {
                    return event.error as ErrorResponse;
                  },
                }),
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          // TODO 后续重构成事件抛出错误
          tags: 'error',
          after: {
            1000: {
              actions: [
                assign({
                  returnError: () => null,
                }),
              ],
            },
          },
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    createNewRequest: {
      initial: 'getDraftReturnsActor',
      states: {
        getDraftReturnsActor: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getDraftReturnsActor',
                src: 'getDraftReturnsActor',
                input: ({ context }) => {
                  return {
                    token: context?.token,
                    orderId: context.orderId,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    draftId: ({ event }) => {
                      return event.output?.draft_returns?.[0]?.id;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              always: [
                {
                  guard: 'isDraftId',
                  target: '#deleteDraftReturnsActor',
                },
                {
                  target: 'done',
                },
              ],
            },
            done: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        deleteDraftReturnsActor: {
          id: 'deleteDraftReturnsActor',
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'deleteDraftReturnsActor',
                src: 'deleteDraftReturnsActor',
                input: ({ context }) => {
                  return {
                    token: context?.token,
                    draftId: context?.draftId!,
                  };
                },
                onDone: {
                  target: 'success',
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              // 在状态里立即消费这个事件，不要在 onDone 里消费
              entry: raise({ type: 'SUBMIT_GENARATE_RETURN' }),
              tags: 'success',
              type: 'final',
            },
            error: {
              tags: 'error',
            },
          },
        },
      },
    },
    deleteDraftReturns: {
      id: 'deleteDraftReturns',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'deleteDraftReturns',
            src: 'deleteDraftReturnsActor',
            input: ({ context, event }) => {
              assertEvent(event, 'DELETE_DRAFT_RETURN');
              return {
                token: context?.token,
                draftId: event?.data?.draftId!,
              };
            },
            onDone: {
              target: 'success',
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
    },
    getReturnById: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'getReturnById',
            src: 'getReturnById',
            input: ({ context, event }) => {
              assertEvent(event, 'GET_RETURN_BY_ID');
              return {
                token: context?.token!,
                returnId: event.data.returnId,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                rmaId: ({ event, context }) => {
                  return context?.returns?.created_return?.rma_id || event.output?.rma_id;
                },
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: {
        target: 'done',
      },
    },

    pollingGetDraftReturn: {
      id: 'pollingGetDraftReturn',
      initial: 'fetching',
      // 初始化 pollCount
      entry: () =>
        assign({
          pollCount: () => 0,
        }),
      states: {
        fetching: {
          tags: 'loading',
          invoke: {
            id: 'getDraftReturnByIdActor',
            src: 'getDraftReturnByIdActor',
            input: ({ context }) => {
              return {
                token: context?.token!,
                draftId: context?.draftId!,
              };
            },
            onDone: {
              target: 'delay',
              actions: assign({
                draftReturn: ({ event }) => event.output,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        delay: {
          after: {
            timeout: {
              target: 'checking',
            },
          },
        },
        checking: {
          always: [
            {
              target: 'success',
              guard: 'isStopPolling',
              actions: [
                assign({
                  rmaId: ({ context }) => {
                    return (
                      context?.returns?.created_return?.rma_id || context?.draftReturn?.return_id
                    );
                  },
                }),
              ],
            },
            {
              target: 'fetching',
              actions: assign({
                pollCount: ({ context }) => {
                  return context.pollCount + 1;
                },
              }),
            },
          ],
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    stopPollingDraftReturn: {
      initial: 'success',
      states: {
        success: {
          type: 'final',
          tags: 'success',
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    updateIntentionData: {
      initial: 'decision',
      states: {
        decision: {
          always: [
            {
              guard: 'isEFAonStore',
              target: 'loading',
            },
            {
              target: 'done',
            },
          ],
        },
        loading: {
          tags: 'loading',
          invoke: {
            src: 'updateIntentionData',
            input: ({ context, event }) => {
              assertEvent(event, 'UPDATE_INTENTION_DATA');
              return {
                token: context.token,
                payload: {
                  ...(context.intentionData as IReturnIntentions),
                  exchange_items: event.data.exchangeItems,
                },
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                exchangeItems: ({ event, context }) => {
                  return event.output?.exchange_items?.reduce((acc, item) => {
                    const currentItem = context.exchangeItems?.find(
                      (i) => i.variantId === item.external_variant_id,
                    );
                    if (currentItem)
                      acc.push({
                        ...currentItem,
                        quantity: item.quantity,
                      });
                    return acc;
                  }, [] as PreviewExchangeItem[]);
                },
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: { tags: 'success', type: 'final' },
        error: { tags: 'error', type: 'final' },
        done: { type: 'final' },
      },
    },
    done: {
      type: 'final',
    },
  },
  on: {
    SUBMIT_GENARATE_RETURN: {
      target: '.postReturnsActor',
    },
    REFETCH_PREVIEW: {
      target: '.fetchReviewDatas',
    },
    CREATE_NEW_REQUEST: {
      target: '.createNewRequest',
    },
    SET_INSTANT_EXCHANGE: {
      actions: assign({
        instantExchange: ({ event }) => event.data.instantExchange,
      }),
    },
    SET_REFUND_DESTINATION: {
      actions: assign({
        refundDestination: ({ event }) => event.data?.refund_destination,
      }),
    },
    GET_RETURN_BY_ID: {
      target: '.getReturnById',
    },
    POLLING_GET_DRAFT_RETURN: {
      target: '.pollingGetDraftReturn',
    },
    POLLING_CANCEL_DRAFT_RETURN: {
      target: '.stopPollingDraftReturn',
    },
    DELETE_DRAFT_RETURN: {
      target: '.deleteDraftReturns',
    },
    UPDATE_REFUND_DESTINATION: {
      actions: assign({
        refundDestination: ({ event }) => event.data.refundDestination,
      }),
    },
    UPDATE_INTENTION_DATA: {
      target: '.updateIntentionData',
    },
    CLEAR_RETURN_ERROR: {
      actions: assign({
        returnError: () => null,
      }),
    },
    UPDATE_EFA_ITEMS: {
      actions: [
        assign({
          exchangeItems: ({ event }) => {
            return event.data.exchangeItems;
          },
        }),
        sendTo(
          ({ self }) => {
            return self._parent!;
          },
          ({ event }) => {
            return {
              type: 'UPDATE_EXCHANGE_ITEMS',
              data: event.data.exchangeItems?.map((item) => ({
                variantId: item.variantId,
                quantity: item.quantity,
              })),
            };
          },
        ),
        sendTo(
          ({ self }) => self,
          ({ event }) => {
            return {
              type: 'UPDATE_INTENTION_DATA',
              data: {
                exchangeItems: event.data.exchangeItems?.map((item) => ({
                  external_product_id: item.productId,
                  external_variant_id: item.variantId,
                  quantity: item.quantity,
                })),
              },
            };
          },
        ),
        sendTo(({ self }) => self, {
          type: 'REFETCH_PREVIEW',
        }),
      ],
    },
    PREVIEW_UPDATE_CONTEXT_FOR_ORIGINAL: {
      actions: 'updateContextForOriginalPaymentInPreview',
    },
    PREVIEW_UPDATE_CONTEXT_FOR_REPLACE: {
      actions: 'updateContextForExchangeInPreview',
    },
  },
});

export type ReviewSubFlowActorRef = ActorRefFrom<typeof reviewSubFlow>;

export type ReviewSubFlowSnapshot = SnapshotFrom<typeof reviewSubFlow>;
